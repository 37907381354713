<template>
  <div class="odn-response-values">
    <div
      v-for="surveyField in formFields"
      :key="surveyField.id"
      class="odn-response-value"
    >
      <ODNLabel
        :label="
          surveyField.name || $t(`surveyFields.${surveyField.field.name}.label`)
        "
        :style="{ color: surveyField.field.color || null }"
      />
      <div
        v-if="surveyField.responseValues.length > 0"
        class="odn-response-value-content"
      >
        <img
          v-if="surveyField.field.name === 'UPLOAD_IMAGE'"
          :src="surveyField.responseValues[0].value"
          :alt="surveyField.responseValues[0].value"
        />
        <video v-else-if="surveyField.field.name === 'UPLOAD_VIDEO'" controls>
          <source :src="surveyField.responseValues[0].value" type="video/mp4" />
        </video>
        <div v-else-if="['UPLOAD_GENERIC'].includes(surveyField.field.name)">
          <ion-button
            color="light"
            expand="block"
            :href="surveyField.responseValues[0].value"
          >
            <ion-icon slot="start" :icon="icons.documentOutline"></ion-icon>
            {{ $t('buttons.download') }}
          </ion-button>
        </div>
        <div v-else-if="['BARCODE', 'IMEI'].includes(surveyField.field.name)">
          {{ surveyField.responseValues[0].value }}
        </div>
        <div
          v-else-if="
            ['QRCODE', 'QRCODE_INSTALL', 'QRCODE_UNINSTALL', 'UNIT'].includes(
              surveyField.field.name
            )
          "
        >
          <ODNJSONValues
            v-if="isJSON(surveyField.responseValues[0].value)"
            :values="surveyField.responseValues[0].value"
          />
          <div v-else>
            {{ surveyField.responseValues[0].value }}
          </div>
        </div>
        <div v-else-if="['PRODUCT'].includes(surveyField.field.name)">
          <ODNResponseValueProduct :id="surveyField.responseValues[0].value" />
        </div>
        <div v-else-if="['LOCATION_STATUS'].includes(surveyField.field.name)">
          <ODNResponseValueLocationStatus
            :id="surveyField.responseValues[0].value"
          />
        </div>
        <div v-else-if="['FURNITURE'].includes(surveyField.field.name)">
          <ODNResponseValueFurniture
            :id="surveyField.responseValues[0].value"
          />
        </div>
        <div v-else>
          {{ surveyField.responseValues[0].value }}
        </div>
      </div>
      <div v-else class="odn-response-value-empty">
        {{ $t('labels.empty') }}
      </div>
    </div>
  </div>
</template>

<script>
import { IonButton, IonIcon } from '@ionic/vue';
import { documentOutline } from 'ionicons/icons';

import ODNLabel from '@c/odn-label.vue';
import ODNJSONValues from '@c/odn-json-values';
import ODNResponseValueProduct from '@c/odn-response-value-product';
import ODNResponseValueLocationStatus from '@c/odn-response-value-location-status';
import ODNResponseValueFurniture from '@c/odn-response-value-furniture';

export default {
  name: 'ODNResponseValues',
  components: {
    IonButton,
    IonIcon,
    ODNLabel,
    ODNJSONValues,
    ODNResponseValueProduct,
    ODNResponseValueLocationStatus,
    ODNResponseValueFurniture,
  },
  props: {
    formFields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      icons: {
        documentOutline,
      },
    };
  },
  methods: {
    isJSON(s) {
      if (!s) return false;
      return s[0] === '{';
    },
  },
};
</script>

<style lang="scss">
.odn-response-values {
  padding: 16px;
}

.odn-response-value {
  padding: 8px;
  margin-bottom: 8px;
  background-color: rgba(var(--ion-color-light-contrast-rgb), 0.05);
  border-radius: 8px;

  > ion-item {
    --background: transparent;
  }

  img,
  video {
    display: block;
    width: calc(100% - 6px);
    margin: 0 auto 8px auto;
    border-radius: 4px;
  }

  ion-button {
    margin-top: 8px;
  }

  &-content,
  &-empty {
    padding: 0 16px 8px 16px;
  }

  &-empty {
    opacity: 0.4;
  }
}
</style>
