<template>
  <div class="odn-card" :style="styles">
    <div v-if="title" class="odn-card-title">
      {{ title }}
    </div>
    <div class="odn-card-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ODNCard',
  components: {},
  props: {
    title: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
  },
  computed: {
    styles() {
      return this.color ? { backgroundColor: this.color } : {};
    },
  },
};
</script>

<style lang="scss">
.odn-card {
  padding: 8px;
  margin-bottom: 8px;
  background-color: rgba(var(--ion-color-light-contrast-rgb), 0.05);
  border-radius: 8px;

  &-title {
    padding: 8px 16px;
  }

  &-content {
    padding: 8px 16px;
  }
}
</style>
