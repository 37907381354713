<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/tabs/stores"></ion-back-button>
        </ion-buttons>
        <ion-title>
          {{
            response && response.survey
              ? response.survey.name
              : $t('messages.loading')
          }}
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content v-if="response">
      <div class="ion-padding">
        <ODNCard :color="response.responseStatus.color">
          {{ $t(`responseStatuses.${response.responseStatus.name}`) }}
        </ODNCard>
        <ODNCard>
          <div>
            {{ $t('labels.submittedBy', { name: response.user.email }) }}
          </div>
          <div>
            {{
              $t('labels.submittedOn', { date: formatDate(response.created) })
            }}
          </div>
          <div v-if="response.modified">
            {{
              $t('labels.modifiedOn', { date: formatDate(response.modified) })
            }}
          </div>
        </ODNCard>
      </div>

      <!-- Response values -->
      <ODNResponseValues :form-fields="formFields" />

      <!-- Comment -->
      <div v-if="response.comment" class="ion-padding">
        <div v-if="response.comment" class="odn-response-value">
          <ODNLabel :label="$t('sections.comment')" />
          <div class="odn-response-value-content">
            <!-- eslint-disable vue/no-v-html -->
            <div v-html="response.comment" style="white-space:pre"></div>
          </div>
        </div>
      </div>

      <!-- Button -->
      <div v-if="editAllowed" class="ion-padding-horizontal ion-margin-bottom">
        <ion-button
          color="primary"
          expand="block"
          :router-link="
            `/tabs/stores/${this.$route.params.id}/surveys/${this.$route.params.surveyId}/responses/${this.$route.params.responseId}/edit`
          "
        >
          {{ $t('buttons.edit') }}
          <ion-icon slot="start" :icon="icons.pencilOutline"></ion-icon>
        </ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonBackButton,
  toastController,
} from '@ionic/vue';
import { pencilOutline } from 'ionicons/icons';

import ODNCard from '@c/odn-card.vue';
import ODNResponseValues from '@c/odn-response-values.vue';
import ODNLabel from '@c/odn-label.vue';

import APIService from '@s/api.service';

export default {
  name: 'StoreSurveyResponse',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonBackButton,
    ODNCard,
    ODNResponseValues,
    ODNLabel,
  },
  data() {
    return {
      loading: true,
      icons: {
        pencilOutline,
      },
    };
  },
  computed: {
    ...mapState('auth', ['authData', 'profile']),
    ...mapState('responses', {
      response: 'selectedResponse',
    }),
    formFields() {
      return this.response.survey?.surveyFields?.filter(f => f.field.isForm);
    },
    editAllowed() {
      // Return true if connected user role is a authorized role
      if (['superadmin', 'admin', 'pm'].includes(this.profile.role.name)) {
        return true;
      }

      // Return true if response user is the connected user
      if (
        this.profile.role.name === 'user' &&
        this.response.user.id === this.authData.userId
      ) {
        return true;
      }

      // Return true if response survey groups is empty
      if (
        this.response.survey.groups &&
        this.response.survey.groups.length <= 0
      ) {
        return true;
      }

      // Return true if response survey groups contains any of the connected user group
      if (
        this.response.survey.groups &&
        this.response.survey.groups.find(sg =>
          this.profile.groups.includes(sg.id)
        )
      ) {
        return true;
      }

      // Otherwise, return false
      return false;
    },
  },
  async ionViewDidEnter() {
    this.fetchData();
  },
  ionViewDidLeave() {
    this.loading = true;
  },
  methods: {
    ...mapActions('responses', ['selectResponse']),
    formatDate(dt) {
      return this.$dayjs(dt)
        .locale(this.$i18n.locale)
        .format('ll - LT');
    },
    async fetchData() {
      try {
        const response = (
          await APIService.get(`/responses/${this.$route.params.responseId}`)
        ).data;
        this.selectResponse(response);
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.surveyResponse.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
