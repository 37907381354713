<template>
  <div class="odn-json-values">
    <div
      v-for="(value, key) in parsedJSON"
      :key="key"
      class="odn-json-value"
      :class="{ faded: !value }"
    >
      <span class="odn-json-value-key">{{ key }}</span>
      <span class="odn-json-value-value">
        {{ value || $t('labels.empty') }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    values: {
      type: String,
      default: null,
    },
  },
  computed: {
    parsedJSON() {
      return this.values ? JSON.parse(this.values) : null;
    },
  },
};
</script>

<style lang="scss">
.odn-json-value {
  display: inline-flex;
  margin-bottom: 8px;

  background-color: rgba(var(--ion-color-light-contrast-rgb), 0.05);
  border: 1px solid rgba(var(--ion-color-light-contrast-rgb), 0.4);
  border-radius: 9999px;

  &.faded {
    opacity: 0.4;
  }

  &:not(:last-child) {
    margin-right: 16px;
  }

  &-key {
    padding: 4px 12px;
    background-color: rgba(var(--ion-color-light-contrast-rgb), 0.4);
    border-radius: 9999px;
  }

  &-value {
    padding: 4px 12px;
  }
}
</style>
