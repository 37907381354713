<template>
  <div class="odn-response-value-location-status">
    {{ $t(`locationStatuses.${locationStatus.name}`) }}
  </div>
</template>

<script>
import APIService from '@s/api.service';

import { toastController } from '@ionic/vue';

export default {
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      processing: true,
      locationStatus: {},
    };
  },
  mounted() {
    this.fetchData(this.id);
  },
  methods: {
    async fetchData(id) {
      this.loading = true;
      try {
        this.locationStatus = (
          await APIService.get(`/location-statuses/${id}`)
        ).data;
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.locationStatus.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
